import { MutationKey } from '@tanstack/react-query';
import { IconName } from '@dvag/design-system-icons/dist/types/types/icon-types';

import i18next from 'i18next';

import { FormType } from 'utils/fieldList';
import {
  MobilePhone,
  PersonalEmail,
  PersonalFax,
  Phone,
  WorkEmail,
  WorkFax,
  WorkMobilePhone,
  WorkPhone,
} from '../service/model/communicationType';

import { SmokerValues, SmokingOption } from '../type/basicData';
import { ICreatePerson } from '../type/createPerson';
import { RemoveType, RemoveTypeObj } from '../type/general';
import { PersonFullName } from '../type/person';
import { TransformParameters } from '../type/taxData';
import { displayCurrency } from './displayCurrency';

const CHURCH_TAX_LIST_VALUE_0 = 0;
const CHURCH_TAX_LIST_VALUE_8 = 8;
const CHURCH_TAX_LIST_VALUE_9 = 9;
const CHURCH_TAX_LIST_VALUES = [
  CHURCH_TAX_LIST_VALUE_0,
  CHURCH_TAX_LIST_VALUE_8,
  CHURCH_TAX_LIST_VALUE_9,
];
const MAX_CHILD_ALLOWANCE = 200;
const CHILD_ALLOWANCE_STEP = 0.5;
const DISPLAY_PRECISION = 1;
const childAllowanceValueList = Array.from(Array(MAX_CHILD_ALLOWANCE).keys());
export const STATUS_FORBIDDEN = 403;
export const STATUS_UNAUTHORIZED = 401;
export const STATUS_BAD_REQUEST = 400;

export const childAllowanceOptionList = childAllowanceValueList.map((number) => {
  const value = `${number * CHILD_ALLOWANCE_STEP}`;
  const label = displayCurrency(value, true, '', '', DISPLAY_PRECISION);

  return {
    label,
    value,
  };
});

export const churchTaxOptionList = CHURCH_TAX_LIST_VALUES.map((number) => {
  const value = `${number}`;
  const label = `${number} %`;

  return {
    label,
    value,
  };
});

export const taxReturnType = [
  {
    value: 'EINZELN',
    label: 'Einzeln',
  },
  {
    value: 'ZUSAMMEN',
    label: 'Zusammen',
  },
];

export const queryKeyStr: MutationKey = ['contact-information-'];

export const xl = 64;

export const defaultCreatePerson: ICreatePerson = {
  salutation: null,
  familyRelationship: false,
  firstName: null,
  lastName: null,
};

export const telephoneTypeOptionList = [
  {
    label: i18next.t('contact.private'),
    value: Phone,
  },
  {
    label: i18next.t('contact.work'),
    value: WorkPhone,
  },
  {
    label: i18next.t('contact.mobil_privat'),
    value: MobilePhone,
  },
  {
    label: i18next.t('contact.mobil_geschaeftlich'),
    value: WorkMobilePhone,
  },
];

export const emailTypeOptionList = [
  {
    label: i18next.t('contact.private'),
    value: PersonalEmail,
  },
  {
    label: i18next.t('contact.work'),
    value: WorkEmail,
  },
];

export const faxTypeOptionList = [
  {
    label: i18next.t('contact.private'),
    value: PersonalFax,
  },
  {
    label: i18next.t('contact.work'),
    value: WorkFax,
  },
];

export const occupationGroupList = [
  {
    value: 'ANGESTELLTE',
    label: 'Angestellte',
  },
  {
    value: 'ANGESTELLTE_IM_D',
    label: 'Angestellte im Ö.D.',
  },
  {
    value: 'ARBEITER',
    label: 'Arbeiter',
  },
  {
    value: 'ARBEITER_IM_D',
    label: 'Arbeiter im Ö.D.',
  },
  {
    value: 'ARBEITSLOSE',
    label: 'Arbeitslose',
  },
  {
    value: 'AUSZUBILDENDE',
    label: 'Auszubildende',
  },
  {
    value: 'BEAMTE',
    label: 'Beamte',
  },
  {
    value: 'BEAMTE_AUF_PROBE',
    label: 'Beamte auf Probe',
  },
  {
    value: 'BEAMTE_AUF_WIDERRUF',
    label: 'Beamte auf Widerruf',
  },
  {
    value: 'DIENSTUNFAEHIGE_BEAMTE',
    label: 'Dienstunfähige Beamte',
  },
  {
    value: 'ERWERBSMINDERUNGSRENTNER',
    label: 'Erwerbsminderungsrentner',
  },
  {
    value: 'GERINGFUEGIG_BESCHAEFTIGTE',
    label: 'Geringfügig Beschäftigte',
  },
  {
    value: 'HAUSFRAUEN_MAENNER',
    label: 'Hausfrauen/männer',
  },
  {
    value: 'KIND',
    label: 'Kind',
  },
  {
    value: 'KINDERERZIEHENDE',
    label: 'Kinderziehende',
  },
  {
    value: 'LANDWIRTE',
    label: 'Landwirte',
  },
  {
    value: 'PENSIONAERE',
    label: 'Pensionäre',
  },
  {
    value: 'RENTNER',
    label: 'Rentner',
  },
  {
    value: 'SCHUELER',
    label: 'Schüler',
  },
  {
    value: 'SELBSTAENDIGE_FREIBERUFLICH',
    label: 'Selbständige (freiberuflich)',
  },
  {
    value: 'SELBSTAENDIGE_GEWERBLICH',
    label: 'Selbstständige (gewerblich)',
  },
  {
    value: 'SELBSTAENDIGE_HANDWERKLICH',
    label: 'Selbständige (handwerklich)',
  },
  {
    value: 'SOLDATEN',
    label: 'Soldaten',
  },
  {
    value: 'STUDENTEN',
    label: 'Studenten',
  },
  {
    value: 'WEHR_UND_ZIVILDIENSTLEISTENDE',
    label: 'Wehr - und Zivildienstleistende',
  },
];

export const salutationList = [
  { value: 'HERR', label: 'Herr' },
  { value: 'FRAU', label: 'Frau' },
];

export const maritalState = [
  { value: 'LEDIG', label: 'Ledig' },
  { value: 'VERHEIRATET', label: 'Verheiratet' },
  { value: 'GESCHIEDEN', label: 'Geschieden' },
  { value: 'GETRENNT_LEBEND', label: 'Getrennt lebend' },
  { value: 'VERWITWET', label: 'Verwitwet' },
  { value: 'EHEAEHNLICHE_GEMEINSCHAFT', label: 'Eheähnliche Gemeinschaft' },
  { value: 'VERPARTNERT', label: 'Verpartnert' },
  {
    value: 'LEBENSPARTNERSCHAFT_AUFGEHOBEN',
    label: 'Lebenspartnerschaft aufgehoben',
  },
  { value: 'LEBENSPARTNER_VERSTORBEN', label: 'Lebenspartner verstorben' },
];

export const academicTitle = [
  { value: 'DR', label: 'Dr.' },
  { value: 'PROF', label: 'Prof.' },
  { value: 'PROF_DR', label: 'Prof. Dr.' },
  { value: 'DR_HC', label: 'Dr. h. c.' },
  { value: 'MAG', label: 'Mag.' },
  { value: 'DIPLOM_KAUFFRAU', label: 'Dipl.-Kffr.' },
  { value: 'DIPLOM_KAUFMANN', label: 'Dipl.-Kfm.' },
  { value: 'INGENIEUR_MAG', label: 'Ingenieur Mag.' },
  { value: 'INGENIEUR', label: 'Ingenieur' },
  { value: 'DI', label: 'DI' },
  { value: 'DIPLOM_JUR', label: 'Dipl.-Jur.' },
  { value: 'DR_ING', label: 'Dr.-Ing.' },
  { value: 'DIPL_ING', label: 'Dipl.-Ing.' },
  { value: 'DR_MED', label: 'Dr. med.' },
  { value: 'DR_JUR', label: 'Dr. jur.' },
  { value: 'BAKK', label: 'Bakk.' },
  { value: 'DR_DR', label: 'Dr. Dr.' },
  { value: 'BETRIEBSWIRT', label: 'Betriebswirt' },
];

export const smokingOption: SmokingOption = { smoker: 'smoker', nonSmoker: 'nonSmoker' };

export const smoker = [
  { value: smokingOption.smoker, label: i18next.t('basicData.yes') },
  { value: smokingOption.nonSmoker, label: i18next.t('basicData.no') },
];

export const familyRelationshipList = {
  KIND: 'KIND',
  ERWACHSEN: 'ERWACHSEN',
};

export const countryIdentifiers = {
  DEUTSCHLAND: 'D',
  AUSLAND: 'AUSLAND',
};

export const dataContext = {
  household: 'HAUSHALT',
  person: 'PERSON',
};

export const getPersonFullName = ({ firstName, lastName }: PersonFullName): string =>
  `${firstName} ${lastName}`;

export const isClient = (personStatus: string | null) => personStatus === 'KUNDE';

export const removeEmpty = (obj: RemoveType): RemoveType => {
  if (typeof obj !== 'object') return obj;
  return Object.entries(obj as RemoveTypeObj)
    .filter(([, value]) => value != null && value !== undefined && value !== '')
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: removeEmpty(value),
      }),
      {},
    );
};

export const transformEmptyToNull = (obj: TransformParameters): TransformParameters => {
  if (obj === '' || obj === null) return null;
  if (typeof obj !== 'object') return obj;
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: transformEmptyToNull(value),
    }),
    {},
  );
};

export const getSmokerOption = (value: undefined | string) => {
  if (value) {
    return value === smokingOption.smoker;
  }
  return undefined;
};

export const isSmoker = (value: boolean | undefined = undefined): SmokerValues => {
  if (typeof value !== 'boolean') return undefined;
  return value ? smokingOption.smoker : smokingOption.nonSmoker;
};

export const removeEmptySpace = <T>(value: T): string | T =>
  typeof value === 'string' ? value.trim() : value;

export const contextPerson = 'PERSON';

export const AUTOSAVE_TIME_OUT = 1500;

export const handleNavigationButtonLabel = (activeForm: string) =>
  activeForm === FormType.bankDetails
    ? i18next.t('general.concludeFlow')
    : i18next.t('general.continue');

export const checkIfChild = (value: string) => value === familyRelationshipList.KIND;

export const checkPermission = (permission: string) => {
  if (!window?.orchestratorContext?.user?.permissions?.includes(permission))
    throw Error('Unauthorized', { cause: 403 });
};

type Assert = (condition: unknown) => asserts condition;

export const assert: Assert = (condition) => {
  if (condition === false) throw new Error('Invalid assertion');
};

export const checkIcon = (icon: IconName): IconName => icon;

export const removeAccents = (str: string): string =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim();
